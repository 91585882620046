import { Caption } from '@tumelo/designsystem'
import React, { useRef } from 'react'
import styled from 'styled-components'

interface Props {
  title: string
}

export const SchemeTruncatedTitle: React.FC<Props> = ({ title }) => {
  const containerRef = useRef<HTMLParagraphElement | null>(null)

  const containerElement = containerRef.current
  const isTruncated = (containerElement && containerElement.scrollWidth > containerElement.clientWidth) || false

  return (
    <StyledCaption mb="0" title={title} ref={containerRef} isTruncated={isTruncated} $semibold>
      {title}
    </StyledCaption>
  )
}

interface StyledCaptionProps {
  isTruncated?: boolean
}

const StyledCaption = styled(Caption)<StyledCaptionProps>`
  color: ${({ theme }) => theme.colors.white};
  width: 14.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: ${({ isTruncated }) => (isTruncated ? 'help' : 'auto')};
`

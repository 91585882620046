import { Option } from '../proto/tumelo/transparency/v1/option';
import { Proposal_Source } from '../proto/tumelo/transparency/v1/proposal';
import { libGeneralMeeting } from './index';
var prefix = "proposals/";
export var IDToName = function (_a) {
    var organizationID = _a.organizationID, generalMeetingID = _a.generalMeetingID, proposalID = _a.proposalID;
    return libGeneralMeeting.IDToName({ organizationID: organizationID, generalMeetingID: generalMeetingID }) + '/' + prefix + proposalID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 6) {
        throw new Error("bad proposal name: ".concat(name));
    }
    return {
        organizationID: parts[1],
        generalMeetingID: parts[3],
        proposalID: parts[5],
    };
};
export var sourceToString = function (o) {
    switch (o) {
        case Proposal_Source.PROPOSAL_SOURCE_INVALID:
            return 'Invalid';
        case Proposal_Source.UNRECOGNIZED:
            return 'Unrecognized';
        case Proposal_Source.PROPOSAL_SOURCE_MANAGEMENT:
            return 'Management';
        case Proposal_Source.PROPOSAL_SOURCE_SHAREHOLDER:
            return 'Shareholder';
    }
};
export var optionToString = function (o) {
    switch (o) {
        case Option.OPTION_INVALID:
            return 'Invalid';
        case Option.UNRECOGNIZED:
            return 'Unrecognized';
        case Option.OPTION_FOR:
            return 'For';
        case Option.OPTION_AGAINST:
            return 'Against';
        case Option.OPTION_ABSTAIN:
            return 'Abstain';
        case Option.OPTION_WITHHOLD:
            return 'Withhold';
        case Option.OPTION_NO_ACTION:
            return 'No Action';
        case Option.OPTION_ONE_YEAR:
            return 'One Year';
        case Option.OPTION_TWO_YEARS:
            return 'Two Years';
        case Option.OPTION_THREE_YEARS:
            return 'Three Years';
    }
};

import { libFundManager } from './index';
export var prefix = 'managedInstruments/';
export var IDToName = function (_a) {
    var fundManagerID = _a.fundManagerID, managedInstrumentIsin = _a.managedInstrumentIsin;
    return libFundManager.IDToName({ fundManagerID: fundManagerID }) + '/' + prefix + managedInstrumentIsin;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 4) {
        throw new Error("expected 4 parts in ".concat(name));
    }
    return {
        fundManagerID: parts[1],
        managedInstrumentIsin: parts[3],
    };
};

import { exactValidator, genericValidateName, SEPARATOR, uuidValidator } from './genericName';
import { libFund } from './index';
export var prefix = 'quantificationSnapshots';
export var prefixWithSeparator = prefix + SEPARATOR;
export var IDToName = function (_a) {
    var fundID = _a.fundID, quantificationSnapshotID = _a.quantificationSnapshotID;
    return libFund.IDToName({ fundID: fundID }) + SEPARATOR + prefixWithSeparator + quantificationSnapshotID;
};
export var validateName = genericValidateName([
    exactValidator(libFund.prefix),
    uuidValidator(),
    exactValidator(prefix),
    uuidValidator(),
]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split(SEPARATOR);
    return {
        fundID: parts[1],
        quantificationSnapshotID: parts[3],
    };
};

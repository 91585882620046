import { libInvestor } from './index';
// habitats/{uuid}/investors/{uuid}/investorSupportedIssuedInstruments/{uuid}
export var prefix = '/investorSupportedIssuedInstruments/';
export var IDToName = function (_a) {
    var habitatID = _a.habitatID, investorID = _a.investorID, issuedInstrumentID = _a.issuedInstrumentID;
    return libInvestor.IDToName({ habitatID: habitatID, investorID: investorID }) + prefix + issuedInstrumentID;
};
export var nameToID = function (name) {
    var parts = name.split('/');
    if (parts.length !== 6) {
        throw new Error("bad investor supported issued instrument name: ".concat(name));
    }
    return { habitatID: parts[1], investorID: parts[3], issuedInstrumentID: parts[5] };
};

import { exactValidator, genericValidateName, uuidValidator } from './genericName';
import { libHabitat, libInvestor } from './index';
export var prefix = 'votingPolicySelections';
export var validateName = genericValidateName([
    exactValidator(libHabitat.prefix),
    uuidValidator(),
    exactValidator(libInvestor.prefix),
    uuidValidator(),
    exactValidator(prefix),
    uuidValidator(),
]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split('/');
    return {
        habitatID: parts[1],
        investorID: parts[3],
        votingPolicySelectionID: parts[5],
    };
};

import { CookieBanner as Banner } from '@tumelo/designsystem'
import { useLocalStorage } from '@tumelo/react'
import React from 'react'

export enum CookieConsentValue {
  Accepted = 'accepted',
  Rejected = 'rejected',
  NotSet = '',
}

export const cookieConsentKey = 'cookieconsent'

interface CookieBannerProps {
  children: React.ReactNode
}

export const CookieBanner: React.FC<CookieBannerProps> = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useLocalStorage(cookieConsentKey, CookieConsentValue.NotSet)
  const onClickAccept = () => {
    setCookieConsent(CookieConsentValue.Accepted)
  }
  const onClickReject = () => {
    setCookieConsent(CookieConsentValue.Rejected)
  }
  if (!(cookieConsent === CookieConsentValue.NotSet)) {
    return <>{children}</>
  }
  return (
    <>
      <Banner onClickAccept={onClickAccept} onClickReject={onClickReject}>
        {children}
      </Banner>
    </>
  )
}

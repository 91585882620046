import { SEPARATOR, exactValidator, genericValidateName, uuidValidator } from './genericName';
import { libHabitat } from './index';
export var prefix = 'investors';
export var prefixWithSeparator = prefix + SEPARATOR;
export var IDToName = function (_a) {
    var habitatID = _a.habitatID, investorID = _a.investorID;
    return libHabitat.IDToName({ habitatID: habitatID }) + SEPARATOR + prefixWithSeparator + investorID;
};
export var validateName = genericValidateName([
    exactValidator(libHabitat.prefix),
    uuidValidator(),
    exactValidator(prefix),
    uuidValidator(),
]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split('/');
    return {
        habitatID: parts[1],
        investorID: parts[3],
    };
};

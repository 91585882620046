import { SEPARATOR, exactValidator, genericValidateName, uuidValidator } from './genericName';
import { libFund } from './index';
export var prefix = 'quantifiedVotes';
export var prefixWithSeparator = prefix + SEPARATOR;
export var IDToName = function (_a) {
    var fundID = _a.fundID, quantifiedVoteID = _a.quantifiedVoteID;
    return libFund.IDToName({ fundID: fundID }) + SEPARATOR + prefixWithSeparator + quantifiedVoteID;
};
export var validateName = genericValidateName([
    exactValidator(libFund.prefix),
    uuidValidator(),
    exactValidator(prefix),
    uuidValidator(),
]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split(SEPARATOR);
    return {
        fundID: parts[1],
        quantifiedVoteID: parts[3],
    };
};

import { SEPARATOR, exactValidator, genericValidateName, uuidValidator } from './genericName';
import { libHabitat, libInvestor, libInvestorAccount } from './index';
export var prefix = 'votingPolicySelection';
export var prefixWithSeparator = prefix + SEPARATOR;
export var IDToName = function (_a) {
    var habitatID = _a.habitatID, investorID = _a.investorID, investorAccountID = _a.investorAccountID, investorAccountVotingPolicySelectionID = _a.investorAccountVotingPolicySelectionID;
    return (libInvestorAccount.IDToName({ habitatID: habitatID, investorID: investorID, investorAccountID: investorAccountID }) +
        SEPARATOR +
        prefixWithSeparator +
        investorAccountVotingPolicySelectionID);
};
export var validateName = genericValidateName([
    exactValidator(libHabitat.prefix),
    uuidValidator(),
    exactValidator(libInvestor.prefix),
    uuidValidator(),
    exactValidator(libInvestorAccount.prefix),
    uuidValidator(),
    exactValidator(prefix),
    uuidValidator(),
]);
export var nameToID = function (name) {
    validateName(name); // throws if invalid
    var parts = name.split('/');
    return {
        habitatID: parts[1],
        investorID: parts[3],
        investorAccountID: parts[5],
        investorAccountVotingPolicySelectionID: parts[7],
    };
};
